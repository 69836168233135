@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300');

$NAVBAR_BACK_COLOR: #656565;
$NAVBAR_TEXT_COLOR: white;

.navbar {
    background-color: $NAVBAR_BACK_COLOR;
    color: white;
}

@media screen and (max-width: 481px) {
    .navbar {
        display: block;
        transform-origin: top center;
        margin: 0;
        padding: 0;
        position: relative;

        ul {
            padding: 0px 0px 0px 0px;
            margin: 0px;
        }
    }

    .header-title-line {
        padding: 0.25rem 0.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .menu-button {
        background-color: transparent;
        border: none;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }


    .menu-icon,
    .menu-icon2,
    .menu-icon3 {
        background-color: $NAVBAR_TEXT_COLOR;
        width: 40px;
        height: 5px;
        border-radius: 5px;
        position: absolute;
        transition: all 0.5s; // all the transitions 
    }


    .menu-icon3 {
        transform: translate(0px, -12px);
    }

    .menu-icon2 {
        transform: translate(0px, 12px);
    }

    .nav {
        background-color: $NAVBAR_BACK_COLOR;
        display: none;
        transform-origin: top center;
        animation: showMenu 0.5s ease-in-out forwards;
    }

    @keyframes showMenu {
        0% {
            transform: scaleY(0);
        }

        80% {
            transform: scaleY(1.2);
        }

        100% {
            transform: scaleY(1);
        }
    }

    .nav ul {
        list-style-type: none;
        display: flex;
        flex-flow: column-reverse nowrap;
    }

    .nav li {
        padding: 0.5rem;
        border-top: 1px solid $NAVBAR_TEXT_COLOR;
    }

    .nav a {
        display: block;
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .nav a:any-link {
        color: $NAVBAR_TEXT_COLOR;
        font-weight: bold;
        text-decoration: none;
    }

    .nav a:hover,
    .nav a:focus {
        transform: scale(1.2);
        transition: all 0.3s;
    }


    h1 {
        a {
            color: white;
            width: 100%;
            text-align: center;
            text-decoration: none;
            //top: 14px;
            font-size: 1.3rem;
            //left: 8px;
        }
    }

    li.title {
        display: none;
    }
}

@media screen and (min-width: 482px) {
    .navbar {
        position: relative;

        .header-title-line {
            display: none;

            h1,
            .menu-button {
                display: none;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            list-style-type: none;
            flex-direction: row-reverse;
            padding: 0.5rem 1rem;
            position: relative;

            li {
                float: right;
                padding: 0.875rem 1rem;

                a {
                    font-size: 1rem;
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                    padding: 0.875rem 1rem;
                    font-family: 'Poppins', sans-serif;
                }
            }

            .menu a:hover {
                background-color: #111;
                border-radius: 1rem;
            }

            .title {
                position: absolute;
                left: 16px;
            }

            .title:hover {
                background-color: #111;
                border-radius: 1rem;
            }

            .title:active {
                transform: scale(1.1);
            }

            a {
                padding: 14px 16px;
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300');

.home {
    font-family: 'Poppins', sans-serif;
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: white;
}
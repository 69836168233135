@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300');

.signIn {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

    @media screen and (max-width: 481px) {
        .rect {
            width: 90%;
        }
    }

    @media screen and (min-width: 482px) {
        .rect {
            width: 60%;
        }
    }

    .rect {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        h3,
        h4,
        input {
            width: 100%;
        }

        input {
            padding-top: 5px;
            padding-bottom: 1rem;
            margin-bottom: 0.3rem;
            text-align: center;
            font-family: 'Poppins', sans-serif;
        }

        button {
            display: flex;
            margin: 0 auto;
            width: 100%;
            border-radius: 1rem;
            border-width: 0;
            height: 2.5rem;
            background-color: rgba(255, 0, 0, 0.828);
            vertical-align: middle;
            font-size: 1rem;
            width: 90%;

            .text {
                justify-content: center;
                text-align: center;
                margin: auto;
                // margin-left: auto;
                // margin-right: auto;
                // margin-top: auto;
                // margin-bottom: auto;
                font-weight: bold;
                color: white;
            }
        }

        button:hover {
            background-color: red;
        }

        button:active {
            transform: scale(1.1);
        }
    }
}

/* The google button */
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google_btn {
    margin: 0 auto;
    width: 184px;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
    cursor: pointer;

    .google_icon_wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: $white;
    }

    .google_icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 14px;
        width: 18px;
        height: 18px;
    }

    .btn_text {
        float: right;
        margin: 11px 11px 0 0;
        color: $white;
        font-size: 14px;
        letter-spacing: 0.2px;
        font-family: "Roboto";
    }

    &:hover {
        box-shadow: 0 0 6px $google-blue;
    }

    &:active {
        background: $button-active-blue;
    }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
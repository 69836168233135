@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300');

.home {
    font-family: 'Poppins', sans-serif;
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: white;

    .home__title {
        text-justify: inter-word;
        justify-content: center;
        text-align-last: center;
        text-align: center;
        font-size: 1.17em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        font-family: inherit;
        font-weight: bold;
    }

    .home__input {
        width: 80%;
        height: 4rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
        font-family: inherit;
        font-weight: bold;
        color: black;
        text-align: center;
        border-radius: 1rem;
        border: 1px solid black;
        background-color: rgba(255, 255, 255, 0.295);
    }

    .home__input::placeholder {
        color: black;
        opacity: 0.75;
        font-size: 1rem;
    }

    .chooseLanguage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1.5rem;
        font-size: inherit;

        .chooseLanguage__title {
            border: none;
            margin: 0;
            font-size: inherit;
        }

        .chooseLanguage__select {
            border-radius: 1rem;
            border-style: groove;
            width: 70%;
            height: 2.5rem;
            margin-top: 0.5rem;
            justify-content: center;
            text-align: center;
        }
    }

    .home__summarize-button {
        border-radius: 1rem;
        border-width: 0px;
        background-color: rgba(255, 0, 0, 0.828);
        width: 30%;
        color: white;
        height: 4rem;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 0.8em;
        text-align: center;

    }

    .home__summarize-button:hover {
        background-color: rgb(255, 0, 0);
    }

    .home__summarize-button:active {
        transform: scale(1.1);
    }

    .home__obtained-text {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .home__footer {
        margin-top: 3rem;
        width: 70%;
        padding-top: 3rem;
        font-size: inherit;
        text-align: center;
        border-top: 1px solid black;
    }

}
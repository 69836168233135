a.youtube_url {
    padding: 0px;
    color: #007fee;
    text-decoration: underline;
    display: inline;
}

li.youtube_url {
    text-align: justify;
    list-style-type: none;
    /* width: 90%; */
    margin: 3%;
    /* margin-left: 5%; */
    margin-bottom: 3%;
    /* margin-left: 10%;
    margin-right: 10%; */
}
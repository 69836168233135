@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300');
$NAVBAR_BACK_COLOR: #333;
$NAVBAR_TEXT_COLOR: #fff;

.params-list {
    text-align: center;
    background-color: $NAVBAR_TEXT_COLOR;
    font-weight: bold;
    list-style-type: none;

    li {
        background-color: $NAVBAR_TEXT_COLOR;
        color: black;
        padding: 0.5rem;

        button {
            border-width: 0px;
            border-radius: 1rem;
            padding: 1rem 2rem;
            background-color: rgba(255, 0, 0, 0.828);
            color: white;
            height: 4rem;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 1em;
            text-align: center;
        }

        button:hover {
            background-color: rgb(255, 0, 0);
        }
    }
}